<template>

<app-content :loading="is.loading">

	<app-content-head title="Content blocks" back="Convention.Pages.Edit" :backParams="$route.meta.backParams">

		<app-content-head-actions-item icon="preview" text="View" :url="parent.url" v-if="parent.status" />
		<app-content-head-actions-item v-if="$authorised('con/pages/access', 'write')" text="Add" icon="create" v-on:click="onCreateClick" />

	</app-content-head>

	<app-content-body :loading="is.loading && is.initialised" :is-empty="!collection.length" placeholder="Sorry, no content blocks found.">

		<transition-group name="list" tag="div">

			<com-block v-for="(block, index) in collection" :key="block.id" :block="block" :first="index + 1 === 1" :last="index + 1 === collection.length" v-on:edit="onEditClick(block.id)" v-on:up="onMoveClick(block.id, 'up')" v-on:down="onMoveClick(block.id, 'down')" v-on:delete="onDeleteClick(block.id)" />

		</transition-group>
	
	</app-content-body>

	<com-create v-if="is.expanded" :page="parent" :types="references.types" :blocks="collection" v-on:close="onCreateClose" />

</app-content>

</template>

<script>

import mixCollection from '@/mixin/collection'

import comCreate from './conventionpagespageblocks/Create'
import comBlock from './conventionpagespageblocks/Block'

export default {

	mixins: [mixCollection],

	components: {
		'com-create': comCreate,
		'com-block': comBlock
	},

	data: function() {

		return {
			references: {
				types: []
			},
			is: {
				expanded: false
			},
			gatekeep: 'convention/pages/page',
			endpoint: 'convention/pages/page/:page/blocks',
			live: 'convention/pages/page/:page/blocks'
		}

	},

	methods: {

		onCreateClick: function() {

			if (!this.is.expanded) {

				this.is.expanded = true

				this.$pubsub.$emit('offset', this.is.expanded)

			}

		},

		onCreateClose: function() {

			this.is.expanded = false

			this.$pubsub.$emit('offset', this.is.expanded)

		},

		onMoveClick: function(id, direction) {

			this.$api.put([this.$endpoint(this.endpoint), 'reorder'], {
				id: id,
				direction: direction
			}).then(async function() {

				await this.load(true)
				
				this.$notify({
					message: ['You have successfully re-ordered the content blocks.']
				})

			}.bind(this))

		}	

	}

}

</script>

<style scoped>

.list-move {
	transition: transform 1s;
}

</style>